@font-face {
	font-family: 'AntDesign';
	src: url('./Fonts/AntDesign.ttf') format('truetype');
}

@font-face {
	font-family: 'Entypo';
	src: url('./Fonts/Entypo.ttf') format('truetype');
}

@font-face {
	font-family: 'EvilIcons';
	src: url('./Fonts/EvilIcons.ttf') format('truetype');
}

@font-face {
	font-family: 'Feather';
	src: url('./Fonts/Feather.ttf') format('truetype');
}

@font-face {
	font-family: 'FontAwesome';
	src: url('./Fonts/FontAwesome.ttf') format('truetype');
}

@font-face {
	font-family: 'FontAwesome5';
	src: url('./Fonts/FontAwesome5_Solid.ttf') format('truetype');
}

@font-face {
	font-family: 'FontAwesome5Brands';
	src: url('./Fonts/FontAwesome5_Brands.ttf') format('truetype');
}

@font-face {
	font-family: 'Fontisto';
	src: url('./Fonts/Fontisto.ttf') format('truetype');
}

@font-face {
	font-family: 'Foundation';
	src: url('./Fonts/Foundation.ttf') format('truetype');
}

@font-face {
	font-family: 'Ionicons';
	src: url('./Fonts/Ionicons.ttf') format('truetype');
}

@font-face {
	font-family: 'MaterialCommunityIcons';
	src: url('./Fonts/MaterialCommunityIcons.ttf') format('truetype');
}

@font-face {
	font-family: 'MaterialIcons';
	src: url('./Fonts/MaterialIcons.ttf') format('truetype');
}

@font-face {
	font-family: 'Octicons';
	src: url('./Fonts/Octicons.ttf') format('truetype');
}

@font-face {
	font-family: 'SimpleLineIcons';
	src: url('./Fonts/SimpleLineIcons.ttf') format('truetype');
}

@font-face {
	font-family: 'Zocial';
	src: url('./Fonts/Zocial.ttf') format('truetype');
}

* {
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}

body {
	overflow-x: hidden;
	overflow-y: auto;
}

.App {
	width: 100%;
}

.Header-Container {
	padding: 20px 20px 0 20px;
	background-color: #23527c;
	color: #fff;
}

.Header-Content {
	max-width: 1200px;
	margin: 0 auto;
}

.Header-Title {
	margin: 0;
	font-size: 18px;
	font-weight: normal;
}

.Search-Container {
	padding: 20px;
	background-color: #23527c;
	color: #fff;
}

.Search-Content {
	max-width: 1200px;
	margin: 0 auto;
}

.Search-Form {
	display: flex;
	background-color: #fff;
	color: #333;
	border-radius: 3px;
}

.Search-Label {
	padding: 10px;
	border-right: 1px solid #eee;
}

.Search-Icon {
}

.Search-Input {
	flex-grow: 1;
	padding: 10px;
	border: 0;
	border-radius: 0 3px 3px 0;
	font-size: inherit;
}

.Search-Input:focus {
	outline: none;
}

.Container {
	max-width: 1200px;
	margin: 0 auto;
	padding-top: 60px;
}

.Result-Row {
	margin-bottom: 60px;
}

.Result-Title {
	margin: 0;
	padding: 1em 20px;
	font-size: 200%;
	font-weight: normal;
	color: #fff;
	background-color: #f66;
}

.Result-List {
	display: flex;
	flex-wrap: wrap;
}

.Result-Icon-Container {
	width: 50%;
	padding: 20px;
	text-align: center;
	outline: 1px solid #eee;
	background-color: #fff;
}

@media (min-width: 480px) {
	.Result-Icon-Container {
		width: 33%;
	}
}

@media (min-width: 600px) {
	.Result-Icon-Container {
		width: 25%;
	}
}

@media (min-width: 768px) {
	.Result-Icon-Container {
		width: 20%;
	}
}

@media (min-width: 992px) {
	.Result-Icon-Container {
		width: 14.27%;
	}
}

.Result-Icon {
	font-size: 35px;
}

.Result-Icon-Name {
	font-size: 14px;
	font-weight: normal;
	opacity: 0.8;
	margin: 10px 0 0 0;
}
